import * as API from '@/api/index'

export default {
    //品牌列表
    skinBrand:params =>{
        return API.POST('api/skinBrand/all',params)
    },
    //创建仪器品牌
    create:params =>{
        return API.POST('api/skinBrand/create',params)
    },
    // 编辑仪器品牌
    update:params =>{
        return API.POST('api/skinBrand/update',params)
    },

}